export const EmptyStateIcon = () => {
  return (
    <svg
      width="244"
      height="240"
      viewBox="0 0 244 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M240.052 120.057C240.052 144.108 232.938 166.577 220.631 185.321C217.921 189.611 214.985 193.45 211.824 197.289C189.806 223.372 156.835 239.97 120.139 239.97C83.4423 239.97 50.4719 223.259 28.4539 197.289C25.2924 193.45 22.3567 189.611 19.6468 185.433C16.4852 180.465 13.4366 175.158 10.9525 169.626C9.03299 165.222 7.11348 160.706 5.75853 155.963C3.38737 148.85 1.8066 141.736 0.9033 134.284C0.564562 132.478 0.45165 130.671 0.338737 128.639C0.225825 125.816 0 122.767 0 119.831C0 86.2963 13.6624 56.1487 35.7932 34.3566C38.0515 32.0984 40.5356 29.953 42.9067 27.8077C63.9084 10.645 90.7816 0.03125 120.139 0.03125C143.625 0.03125 165.643 6.80599 184.273 18.5489C185.176 19.0005 185.967 19.5651 186.87 20.2426C187.66 20.8071 188.564 21.2588 189.241 21.9363C193.758 25.0978 198.161 28.5981 202.113 32.4371C204.484 34.6954 206.856 36.9536 208.888 39.4377C211.485 42.3734 214.082 45.3091 216.34 48.5836C216.905 49.374 217.356 50.0515 218.034 50.8418C218.598 51.6322 219.05 52.3097 219.728 53.1001C226.502 63.1493 231.696 74.2147 235.31 85.9576C235.874 88.1029 236.552 90.0225 237.003 92.1678C238.923 100.975 240.052 110.347 240.052 120.057Z"
        fill="#FFF7ED"
      />
      <path
        d="M230 185.32V197.289H28.3384C25.1769 193.45 22.2411 189.611 19.5312 185.433H230V185.32Z"
        fill="#A3A3A3"
      />
      <path
        d="M235.532 93.1828H137.75V86.9727H233.838C234.629 88.8922 235.081 91.0375 235.532 93.1828Z"
        fill="#A16207"
      />
      <path
        d="M216.79 51.7445V87.7636H166.883V21.3711H185.4C186.191 21.9357 187.094 22.3873 187.772 23.0648C192.288 26.2263 196.692 29.7266 200.644 33.5656C203.015 35.8239 205.386 38.0821 207.418 40.5662C210.015 43.5019 212.612 46.4377 214.871 49.7121C215.661 50.2767 216.338 51.0671 216.79 51.7445Z"
        fill="white"
      />
      <path
        d="M216.792 51.7425C216.227 50.9522 215.776 50.2747 215.098 49.4843V86.1808H168.578V22.9499H187.774C186.983 22.3853 186.306 21.9337 185.402 21.2562C184.612 20.6916 183.709 20.24 182.805 19.5625H165.078V89.2295H218.147V54.0008C217.808 53.3233 217.357 52.5329 216.792 51.7425Z"
        fill="#A16207"
      />
      <path
        d="M207.65 40.4537V75.6824H175.922V33.4531H200.65C203.247 35.7114 205.505 37.9696 207.65 40.4537Z"
        fill="#B45309"
      />
      <path
        d="M81.0753 183.287C81.0753 184.304 80.2849 185.094 79.3816 185.433C79.2687 185.433 79.0429 185.433 78.9299 185.433H19.538C16.3765 180.465 13.3278 175.158 10.8438 169.625C13.5536 165.108 15.1344 160.253 15.8119 155.963C16.1506 154.495 16.2635 153.14 16.3765 151.672H61.2027C61.3156 153.027 61.6543 154.382 61.9931 155.963C63.6868 164.092 68.0903 174.254 77.3492 181.142H78.817C80.172 181.142 81.0753 182.158 81.0753 183.287Z"
        fill="#9CA3AF"
      />
      <path
        d="M123.184 33.1162V150.884C123.184 153.707 120.926 155.852 118.216 155.852H5.755C3.49675 149.077 1.91597 141.964 1.01267 134.512C0.673937 132.705 0.561025 130.898 0.448112 128.866C0.3352 125.93 0.109375 122.995 0.109375 120.059C0.109375 86.5238 13.7718 56.3762 35.9026 34.5841C38.1609 32.3258 40.6449 30.1805 43.0161 28.0352H117.99C121.039 28.0352 123.184 30.5192 123.184 33.1162Z"
        fill="#D1D5DB"
      />
      <path
        d="M123.184 33.1162V134.512H1.01267C0.673937 132.705 0.561025 130.898 0.448112 128.866C0.3352 125.93 0.109375 122.994 0.109375 120.059C0.109375 86.5238 13.7718 56.3762 35.9026 34.5841C38.1609 32.3258 40.6449 30.1805 43.0161 28.0352H117.99C121.039 28.0352 123.184 30.5192 123.184 33.1162Z"
        fill="#52525B"
      />
      <path
        d="M116.639 34.582V128.977H0.5653C0.452387 126.041 0.226562 123.105 0.226562 120.17C0.226562 86.6347 13.889 56.487 36.0198 34.6949H116.639V34.582Z"
        fill="white"
      />
      <path
        d="M38.2758 149.868C40.534 149.868 42.3406 148.061 42.3406 145.803C42.3406 143.545 40.534 141.738 38.2758 141.738C36.0175 141.738 34.2109 143.545 34.2109 145.803C34.2109 148.061 36.0175 149.868 38.2758 149.868Z"
        fill="white"
      />
      <path
        d="M60.6334 157.773V162.628H57.246C55.3265 162.628 53.6328 164.322 53.6328 166.241V173.016C53.6328 174.935 55.3265 176.629 57.246 176.629H60.6334V181.823C60.6334 183.63 62.1012 185.21 64.0208 185.21H79.0381C80.8447 185.21 82.4255 183.743 82.4255 181.823V157.547H60.6334V157.773ZM57.246 174.145C56.7944 174.145 56.3427 173.693 56.3427 173.242V166.467C56.3427 166.015 56.7944 165.564 57.246 165.564H60.6334V174.258H57.246V174.145Z"
        fill="#6D28D9"
      />
      <path
        d="M71.6961 129.203C73.0511 132.59 73.3898 135.978 71.3574 138.575C69.4379 141.285 67.97 144.898 70.0024 147.721C71.922 150.431 72.5994 152.576 71.809 153.931C71.0187 155.286 71.809 153.818 74.406 150.882C76.6643 148.511 72.5994 146.479 72.4865 144.559C72.3736 142.753 74.6318 138.914 74.6318 136.43C74.5189 133.72 73.2769 130.219 71.6961 129.203Z"
        fill="white"
      />
      <path
        d="M66.0554 143.996C66.62 145.69 66.8458 147.383 65.9425 148.738C65.0392 150.093 64.2488 151.9 65.378 153.255C66.3942 154.61 66.62 155.626 66.2813 156.416C65.9425 157.207 66.2813 156.416 67.6362 154.949C68.6524 153.707 66.7329 152.69 66.62 151.787C66.5071 150.884 67.6362 149.077 67.6362 147.722C67.5233 146.254 66.9587 144.561 66.0554 143.996Z"
        fill="white"
      />
      <path
        d="M126.461 160.931C127.703 158.221 131.881 153.027 136.171 150.882C136.736 150.543 137.413 150.769 137.639 151.333C138.881 153.479 141.93 158.899 140.801 164.205C139.333 170.641 126.461 160.931 126.461 160.931Z"
        fill="#1F2937"
      />
      <path
        d="M121.158 161.384C121.158 161.384 115.738 155.512 112.238 153.367C111.335 152.916 110.319 153.254 109.867 154.158C106.028 161.384 106.028 169.739 106.931 174.143C107.947 178.885 116.077 165.788 116.077 165.788L121.158 161.384Z"
        fill="#1F2937"
      />
      <path
        d="M118.672 163.304C118.672 163.304 115.284 159.691 113.252 158.336C112.687 158.11 112.01 158.223 111.784 158.787C109.526 163.304 109.413 168.385 110.09 171.095C110.768 174.03 115.736 165.901 115.736 165.901L118.672 163.304Z"
        fill="#737373"
      />
      <path
        d="M129.961 161.271C130.638 159.464 133.348 156.077 135.945 154.722C136.284 154.609 136.623 154.722 136.962 155.061C137.639 156.416 139.558 159.916 138.881 163.416C137.978 167.707 129.961 161.271 129.961 161.271Z"
        fill="#737373"
      />
      <path
        d="M235.879 231.165C224.362 226.31 226.055 211.18 227.184 197.178C228.201 197.178 229.104 197.178 230.12 197.178C230.12 193.226 230.12 189.162 230.12 185.21C230.12 185.097 224.813 185.323 224.249 185.323C221.652 185.323 220.297 183.855 218.152 182.951C218.377 183.064 217.587 189.162 217.474 189.839C213.748 206.211 206.183 211.97 213.296 219.535C215.442 221.793 216.458 226.649 211.49 228.794C206.522 230.713 203.586 223.487 201.892 219.987C198.956 213.889 201.44 204.856 204.602 186.339C204.602 186.339 169.486 186.226 159.437 186.339C158.76 189.162 153.114 208.808 152.324 212.873C151.082 218.97 153.114 223.374 153.566 226.649C154.017 230.149 148.146 232.52 143.742 228.568C138.097 223.374 144.081 215.357 144.871 197.517C144.871 196.501 145.21 185.548 144.533 185.548C132.903 185.548 121.386 185.435 109.756 185.661C108.401 185.661 106.368 186.226 105.578 184.984C99.2547 175.725 115.288 154.836 133.128 160.256C136.064 161.159 139.226 163.079 142.387 163.305C142.387 163.305 144.194 162.74 153.34 156.078C168.696 147.271 188.004 147.949 199.973 150.771C216.119 154.61 218.942 167.595 223.571 173.354C230.233 181.709 236.556 183.855 235.653 204.292C235.314 212.534 235.201 216.938 241.976 221.68C246.493 225.294 242.879 234.101 235.879 231.165Z"
        fill="white"
      />
      <path
        d="M128.157 183.625C126.915 183.738 125.334 183.963 123.866 183.963C123.415 183.963 122.85 183.963 122.398 184.076C117.43 184.189 112.688 183.512 112.01 179.786C111.559 176.85 115.737 173.124 117.995 171.882C125.673 167.591 133.577 173.914 134.48 177.753C135.157 181.028 131.657 183.06 128.157 183.625Z"
        fill="#334155"
      />
      <path
        d="M123.188 179.563C124.43 179.563 125.446 179.111 125.446 178.434C125.446 177.756 124.43 177.305 123.188 177.305C121.946 177.305 120.93 177.756 120.93 178.434C120.93 179.111 121.946 179.563 123.188 179.563Z"
        fill="#989FB0"
      />
      <path
        d="M124.313 179.562V183.966C123.635 183.966 122.732 184.079 122.055 184.079V179.675L124.313 179.562Z"
        fill="#989FB0"
      />
      <path
        d="M153.452 226.647C153.903 230.147 148.032 232.518 143.628 228.567C139.225 224.502 143.29 210.839 144.419 203.613C144.983 199.774 144.983 195.144 144.87 191.531C146.79 193.902 151.532 199.887 154.919 203.048C153.903 207 152.774 210.613 152.323 213.098C151.08 218.856 152.887 223.26 153.452 226.647Z"
        fill="#1C1917"
      />
      <path
        d="M213.067 219.647C215.212 221.906 216.228 226.761 211.26 228.906C206.292 230.826 203.356 223.599 201.662 220.099C199.969 216.599 200.307 208.243 201.662 201.469C202.114 199.097 202.679 196.5 203.017 194.242C204.034 194.807 205.163 195.484 206.066 196.162C208.437 197.968 210.808 200.114 213.518 201.581C212.276 205.082 211.26 207.905 210.582 209.26C209.115 212.986 210.921 217.389 213.067 219.647Z"
        fill="#1C1917"
      />
      <path
        d="M241.855 230.482C238.468 233.643 232.709 230.03 229.548 226.417C226.386 222.804 225.709 211.512 226.838 201.689C226.951 199.995 227.177 198.527 226.951 197.173H230V185.204H220.741C220.628 185.091 220.402 184.865 220.289 184.752C220.402 184.301 220.628 183.736 220.741 183.284C221.531 180.913 220.289 178.542 222.999 173.461C223.789 174.703 224.467 175.38 225.257 176.058C226.725 177.413 228.306 178.429 231.016 182.268C236.323 189.494 235.532 204.738 235.306 210.722C235.194 216.932 239.371 220.771 241.968 221.787C244.226 222.917 245.13 227.32 241.855 230.482Z"
        fill="#1C1917"
      />
      <path
        d="M131.092 166.238H130.979L127.817 165.787C127.253 165.787 126.914 165.222 126.914 164.77C126.914 164.206 127.479 163.867 127.93 163.867L131.092 164.319C131.656 164.319 131.995 164.883 131.995 165.335C131.995 166.013 131.543 166.238 131.092 166.238Z"
        fill="#D4D4D8"
      />
      <path
        d="M115.849 166.578H115.962L119.123 166.127C119.688 166.127 120.026 165.562 120.026 165.11C120.026 164.546 119.462 164.207 119.01 164.207L115.849 164.659C115.284 164.659 114.945 165.223 114.945 165.675C115.171 166.127 115.397 166.465 115.849 166.578Z"
        fill="#D4D4D8"
      />
      <path
        d="M140.309 86.6333L139.359 81.1847L160.68 77.4691L161.629 82.9176L150.115 102.987L164.921 100.407L165.871 105.855L143.603 109.736L142.653 104.287L154.167 84.2181L140.309 86.6333Z"
        fill="#E0F2FE"
      />
      <path
        d="M132.145 124.219L133.135 120.98L145.661 124.807L144.671 128.047L133.114 135.731L141.753 138.371L140.763 141.611L127.589 137.585L128.579 134.346L140.136 126.661L132.145 124.219Z"
        fill="#E0F2FE"
      />
      <path
        d="M219.164 185.547H230.003V185.434V197.402H216.68"
        fill="#D5DDEA"
      />
    </svg>
  );
};
