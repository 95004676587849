import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@tremor/react';
import RolesTab from './RolesTab';
import UsersTab from './UsersTab';

export default function SettingsModal({ open, setOpen, file }: any) {
  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center ">
          <DialogPanel
            transition
            className="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in "
          >
            <div className="bg-white px-6  pt-4 ">
              <div className="flex flex-col">
                <div className="mt-4 text-left">
                  <DialogTitle
                    as="h6"
                    className="text-base font-semibold text-gray-900"
                  >
                    Settings
                  </DialogTitle>
                  <div className="mb-4 mt-1">
                    <p className="text-sm text-gray-500">
                      Configure document visibility for other users
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='px-6'>
              <TabGroup>
                <TabList variant="solid" color="white" className='w-full px-2 py-1'>
                  <Tab className='flex justify-center w-[50%]'>By Roles</Tab>
                  <Tab className='flex justify-center w-[50%]'>Users</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <RolesTab />
                  </TabPanel>
                  <TabPanel>
                    <UsersTab/>
                  </TabPanel>
                </TabPanels>
              </TabGroup>
            </div>

            <div className="flex flex-row justify-end gap-3 px-6 py-5">
              <button
                type="button"
                data-autofocus
                onClick={() => setOpen(false)}
                className="inline-flex justify-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="button"
                className="inline-flex justify-center rounded-md bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-sm  hover:bg-red-500"
              >
                Confirm
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
