import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { Menu, Popover, Position } from 'evergreen-ui';
import { Checkbox } from '../../../ui/component/checkbox';
import { useState } from 'react';
import folderManagement from '../../../network/folderManagement';


export default function CreateNewFolderModal({ open, setOpen, refetchFolders, files }: any) {
  const [ selectedFileOptions, setSelectedFileOptions ] = useState<string[]>([]);
  const [ folderName, setFolderName ] = useState<string>("");

  const handleFileOptionCheckbox = (id: string) => {
    setSelectedFileOptions((prev) => {
      if (prev.includes(id)) {
        return prev.filter((option) => option !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const handleCreateFolder = async () => {
    if (folderName.length < 1) {
      return
    } else {
      try {
        await folderManagement.createFolder(folderName, selectedFileOptions);
        refetchFolders();
        setOpen(false)
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center ">
          <DialogPanel
            transition
            className="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in "
          >
            <div className="px-6 pt-4 ">
              <div className="flex flex-col">
                <div className="mt-4 text-left">
                  <DialogTitle
                    as="h6"
                    className="text-base font-semibold text-gray-900"
                  >
                    Create new folder
                  </DialogTitle>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3 px-6 pb-4">
              <input
                onChange={(event) => setFolderName(event.target.value)}
                placeholder="Enter folder name"
                className="w-full rounded-lg border-gray-300 shadow-sm"
              />
              <div className="flex flex-col gap-1">
                <label className="text-sm font-semibold">Include Files</label>
                <Popover
                  minWidth={'400px'}
                  position={Position.BOTTOM}
                  content={
                    <Menu>
                      <Menu.Group>
                        <div className="flex w-full flex-col">
                          <Menu.Item>
                            <p className="text-gray-400">
                              Choose files that will appear in this folder
                            </p>
                          </Menu.Item>
                          {files.map((item: any) => (
                            <Menu.Item
                              key={item.id}
                              onClick={() => handleFileOptionCheckbox(item.id)}
                            >
                              <div className="flex flex-row items-center gap-2">
                                <Checkbox
                                  color="white"
                                  onChange={() =>
                                    handleFileOptionCheckbox(item.id)
                                  }
                                  checked={selectedFileOptions.includes(
                                    item.id,
                                  )}
                                />
                                {item.name}
                              </div>
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Group>
                    </Menu>
                  }
                >
                  <div className="flex w-full justify-between rounded-lg  border-[1px] border-gray-300 px-2 px-3 py-2 shadow-sm">
                    Select
                    <ChevronUpDownIcon className="w-5" color="gray" />
                  </div>
                </Popover>
              </div>
            </div>
            <div className="flex flex-row justify-end gap-3 px-6 pb-5">
              <button
                type="button"
                data-autofocus
                onClick={() => setOpen(false)}
                className="inline-flex justify-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
              onClick={handleCreateFolder}
                type="button"
                className="inline-flex justify-center rounded-md bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-sm  hover:bg-red-500"
              >
                Create
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
