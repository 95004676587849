export const BigFolderIcon = () => {
  return (
    <svg
      width="81"
      height="68"
      viewBox="0 0 81 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M70.9954 67.5H9.91595C8.17225 67.5 6.89454 67.4996 5.88618 67.4172C4.88366 67.3353 4.19149 67.1749 3.61087 66.879C2.53882 66.3328 1.66722 65.4612 1.12099 64.3891C0.825145 63.8085 0.664684 63.1163 0.582775 62.1138C0.500389 61.1055 0.5 59.8278 0.5 58.084V9.91595C0.5 8.17225 0.500389 6.89454 0.582775 5.88618C0.664684 4.88366 0.825145 4.19149 1.12099 3.61087C1.66722 2.53882 2.53882 1.66722 3.61087 1.12099C4.19149 0.825146 4.88366 0.664684 5.88618 0.582775C6.89454 0.500389 8.17225 0.5 9.91595 0.5H22.2937C23.3355 0.5 24.1195 0.593875 24.9304 0.898451C25.7501 1.20634 26.6302 1.74192 27.8413 2.67681C29.0752 3.62929 29.1811 3.71664 29.2889 3.80155C29.3936 3.88398 29.5 3.96411 30.6419 4.83113C33.0975 6.69554 35.5999 6.86962 37.4861 6.86962H70.9954C72.7391 6.86962 74.0169 6.87001 75.0252 6.95239C76.0277 7.0343 76.7199 7.19477 77.3005 7.49061C78.3726 8.03684 79.2442 8.90844 79.7904 9.98049C80.0863 10.5611 80.2467 11.2533 80.3286 12.2558C80.411 13.2642 80.4114 14.5419 80.4114 16.2856V58.084C80.4114 59.8278 80.411 61.1055 80.3286 62.1138C80.2467 63.1163 80.0863 63.8085 79.7904 64.3891C79.2442 65.4612 78.3726 66.3328 77.3005 66.879C76.7199 67.1749 76.0277 67.3353 75.0252 67.4172C74.0169 67.4996 72.7391 67.5 70.9954 67.5Z"
        fill="#EAB308"
        stroke="#FACC15"
      />
      <rect
        x="0.5"
        y="12.0352"
        width="79.9114"
        height="55.4658"
        rx="5.69747"
        fill="#DC2626"
        stroke="#EF4444"
      />
    </svg>
  );
};
