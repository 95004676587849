import axios from 'axios';

const API_HOST = process.env.REACT_APP_API_HOST;

async function getFolders() {
  const { data } = await axios.get(`${API_HOST}/v1/document/folder`);
  return data;
}

async function createFolder(name: string, files?: string[]) {
  const { data } = await axios.post(`${API_HOST}/v1/document/folder`,{
    name: name,
    files: files,
  });
  return data;
}

async function getFolderById(id: string | number) {
  const { data } = await axios.get(`${API_HOST}/v1/document/folder/${id}`)
  return data;
}

async function updateFolder(id: string, newName: string) {
  const  data  = axios.patch(`${API_HOST}/v1/document/folder/${id}`, {
    name: newName
  });
  return data;
}

async function deleteFolder(id: string){
  const data = axios.delete(`${API_HOST}/v1/document/folder/${id}`);
  return data;
}

export default {
  getFolders,
  createFolder,
  getFolderById,
  updateFolder,
  deleteFolder
}