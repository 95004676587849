import { Icon } from '@tremor/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { ChevronLeftIcon, FunnelIcon } from '@heroicons/react/24/solid';
import { Menu, Popover, Position } from 'evergreen-ui';
import { LeaveTypesTable } from './LeaveTypesTable';
import React, { useState } from 'react';
import { LeaveTypeDialog } from './LeaveTypeDialog';
import { EmptyStateIcon } from '../../../assets/EmptyState/EmptyStateIcon';
import { Checkbox } from '../../../ui/component/checkbox';

const fakeTable = [
  {
    id: '1',
    title: 'Vacation',
    leaveStatus: 'Paid',
    workExperience: '+1 year',
    quantityOfDays: '20',
  },
  {
    id: '2',
    title: 'Sick leave',
    leaveStatus: 'Unpaid',
    workExperience: 'up to 4 months',
    quantityOfDays: '45',
  },
  {
    id: '3',
    title: 'Vacation',
    leaveStatus: 'Paid',
    workExperience: '+1 year',
    quantityOfDays: '20',
  },
  {
    id: '4',
    title: 'Sick leave',
    leaveStatus: 'Unpaid',
    workExperience: 'up to 4 months',
    quantityOfDays: '45',
  },
];

const leaveTypesOptions = [
  {
    id: 1,
    label: 'Vacation',
  },
  {
    id: 2,
    label: 'Sick leave',
  },
  {
    id: 3,
    label: 'Parental leave',
  },
  {
    id: 4,
    label: 'Holiday',
  },
  {
    id: 5,
    label: 'Working remotely',
  }
];

const leaveStatusOptions = [
  {
    id: 1,
    label: 'Paid',
  },
  {
    id: 2,
    label: 'Unpaid'
  }
];

const workExperienceOptions = [
  {
    id: 1,
    label: 'up to 6 months',
  },
  {
    id: 2,
    label: 'up to 1 year',
  },
  {
    id: 3,
    label: 'up to 1,5 years',
  },
  {
    id: 4,
    label: 'up to 2 years',
  },
  {
    id: 5,
    label: 'up to 3 years',
  }
];

const daysOptions = [
  {
    id: 1,
    label: 'up to 7 days',
  },
  {
    id: 2,
    label: 'up to 10 days',
  },
  {
    id: 3,
    label: 'up to 30 days',
  }
]

export const LeaveTypesComponent = () => {
  const [isAddNewTypeOpen, setIsAddNewTypeOpen] = useState<boolean>(false);
  const [ selectedLeaveTypes, setSelectedLeaveTypes ] = useState<string[]>([]);
  const [ selectedLeaveStatus, setSelectedLeaveStatus ] = useState<string[]>([]);
  const [ selectedWorkExperience, setSelectedWorkExperience ] = useState<string[]>([]);
  const [ selectedDays, setSelectedDays ] = useState<string[]>([]);

  const handleCheckboxClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleLeaveTypesClearAll = (event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedLeaveTypes([]);
  }

  const handleLeaveTypesApply = (event: React.MouseEvent) => {
    event.stopPropagation();
  }

  const handleLeaveStatusClearAll = (event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedLeaveStatus([]);
  }

  const handleLeaveStatusApply = (event: React.MouseEvent) => {
    event.stopPropagation();
  }

  const handleWorkExperienceClearAll = (event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedWorkExperience([]);
  }

  const handleWorkExperienceApply = (event: React.MouseEvent) => {
    event.stopPropagation();
  }

  const handleSelectDaysClearAll = (event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedDays([]);
  }

  const handleSelectDaysApply = (event: React.MouseEvent) => {
    event.stopPropagation();
  }

  return (
    <>
      <div className="mt-5">
        <div className="mb-5 flex flex-row items-center justify-between">
          <h4 className="text-lg font-semibold text-tremor-content-strong">
            Leave Types
          </h4>

          {fakeTable.length > 0 ? (
            <div className="flex flex-row gap-4">
              <Popover
                minWidth={'100px'}
                content={
                  <Menu>
                    <Menu.Group>
                      <Popover
                        position={Position.LEFT}
                        content={
                          <Menu>
                            <Menu.Group>
                              <div className="flex flex-col gap-1">
                            {leaveTypesOptions.map((item) => (
                              <Menu.Item
                                key={item.id}
                                onClick={handleCheckboxClick}
                              >
                                <div className="flex flex-row items-center gap-2">
                                  <Checkbox
                                    className="rounded-md border-[1px]"
                                    color="rose"
                                    checked={selectedLeaveTypes.includes(item.label)}
                                    onChange={() => {
                                      setSelectedLeaveTypes((prev) =>
                                        prev.includes(item.label)
                                          ? prev.filter((name) => name !== item.label)
                                          : [...prev, item.label]
                                      );
                                    }}
                                  />
                                  {item.label}
                                </div>
                              </Menu.Item>
                            ))}
                          </div>
                              <div className="mt-1 flex flex-row justify-center gap-2">
                                <button
                                  className=" w-[40%] rounded-lg border-[1px] py-1 shadow"
                                  onClick={handleLeaveTypesClearAll}
                                >
                                  Clear all
                                </button>
                                <button
                                  onClick={handleLeaveTypesApply}
                                  className="w-[40%] rounded-lg border-[1px] shadow"
                                >
                                  Apply
                                </button>
                              </div>
                            </Menu.Group>
                          </Menu>
                        }
                      >
                        <Menu.Item>
                          <div className="flex flex-row flex-nowrap gap-2">
                            <ChevronLeftIcon className="w-4" />
                            Leave Type
                          </div>
                        </Menu.Item>
                      </Popover>

                      <Popover
                        position={Position.LEFT}
                        content={
                          <Menu>
                            <Menu.Group>
                              <div className="flex flex-col gap-1">
                            {leaveStatusOptions.map((item) => (
                              <Menu.Item
                                key={item.id}
                                onClick={handleCheckboxClick}
                              >
                                <div className="flex flex-row items-center gap-2">
                                  <Checkbox
                                    className="rounded-md border-[1px]"
                                    color="rose"
                                    checked={selectedLeaveStatus.includes(item.label)}
                                    onChange={() => {
                                      setSelectedLeaveStatus((prev) =>
                                        prev.includes(item.label)
                                          ? prev.filter((label) => label !== item.label)
                                          : [...prev, item.label]
                                      );
                                    }}
                                  />
                                  {item.label}
                                </div>
                              </Menu.Item>
                            ))}
                          </div>
                              <div className="mt-1 flex flex-row justify-center gap-2">
                                <button
                                  className=" w-[40%] rounded-lg border-[1px] py-1 shadow"
                                  onClick={handleLeaveStatusClearAll}
                                >
                                  Clear all
                                </button>
                                <button
                                  onClick={handleLeaveStatusApply}
                                  className="w-[40%] rounded-lg border-[1px] shadow"
                                >
                                  Apply
                                </button>
                              </div>
                            </Menu.Group>
                          </Menu>
                        }
                      >
                        <Menu.Item>
                          <div className="flex flex-row flex-nowrap gap-2">
                            <ChevronLeftIcon className="w-4" />
                            Leave Status
                          </div>
                        </Menu.Item>
                      </Popover>

                      <Popover
                        position={Position.LEFT}
                        content={
                          <Menu>
                            <Menu.Group>
                              <div className="flex flex-col gap-1">
                            {workExperienceOptions.map((item) => (
                              <Menu.Item
                                key={item.id}
                                onClick={handleCheckboxClick}
                              >
                                <div className="flex flex-row items-center gap-2">
                                  <Checkbox
                                    className="rounded-md border-[1px]"
                                    color="rose"
                                    checked={selectedWorkExperience.includes(item.label)}
                                    onChange={() => {
                                      setSelectedWorkExperience((prev) =>
                                        prev.includes(item.label)
                                          ? prev.filter((label) => label !== item.label)
                                          : [...prev, item.label]
                                      );
                                    }}

                                  />
                                  {item.label}
                                </div>
                              </Menu.Item>
                            ))}
                          </div>
                              <div className="mt-1 flex flex-row justify-center gap-2">
                                <button
                                  className=" w-[40%] rounded-lg border-[1px] py-1 shadow"
                                  onClick={handleWorkExperienceClearAll}
                                >
                                  Clear all
                                </button>
                                <button
                                  onClick={handleWorkExperienceApply}
                                  className="w-[40%] rounded-lg border-[1px] shadow"
                                >
                                  Apply
                                </button>
                              </div>
                            </Menu.Group>
                          </Menu>
                        }
                      >
                        <Menu.Item>
                          <div className="flex flex-row flex-nowrap gap-2">
                            <ChevronLeftIcon className="w-4" />
                            Work experience
                          </div>
                        </Menu.Item>
                      </Popover>

                      <Popover
                        position={Position.LEFT}
                        content={
                          <Menu>
                            <Menu.Group>
                              <div className="flex flex-col gap-1">
                            {daysOptions.map((item) => (
                              <Menu.Item
                                key={item.id}
                                onClick={handleCheckboxClick}
                              >
                                <div className="flex flex-row items-center gap-2">
                                  <Checkbox
                                    className="rounded-md border-[1px]"
                                    color="rose"
                                    checked={selectedDays.includes(item.label)}
                                    onChange={() => {
                                      setSelectedDays((prev) =>
                                        prev.includes(item.label)
                                          ? prev.filter((label) => label !== item.label)
                                          : [...prev, item.label]
                                      );
                                    }}

                                  />
                                  {item.label}
                                </div>
                              </Menu.Item>
                            ))}
                          </div>
                              <div className="mt-1 flex flex-row justify-center gap-2">
                                <button
                                  className=" w-[40%] rounded-lg border-[1px] py-1 shadow"
                                  onClick={handleSelectDaysClearAll}
                                >
                                  Clear all
                                </button>
                                <button
                                  onClick={handleSelectDaysApply}
                                  className="w-[40%] rounded-lg border-[1px] shadow"
                                >
                                  Apply
                                </button>
                              </div>
                            </Menu.Group>
                          </Menu>
                        }
                      >
                        <Menu.Item>
                          <div className="flex flex-row flex-nowrap gap-2">
                            <ChevronLeftIcon className="w-4" />
                            Days
                          </div>
                        </Menu.Item>
                      </Popover>
                    </Menu.Group>
                  </Menu>
                }
              >
                <button
                  className="flex items-center 
            rounded-lg border-[1px] px-3 py-1 font-normal shadow"
                >
                  <Icon icon={FunnelIcon} size="sm" color="blue" />
                  Filter
                </button>
              </Popover>
              <button
                onClick={() => setIsAddNewTypeOpen(true)}
                className="flex items-center gap-1 rounded-lg bg-[#DC2626] px-5 py-1 text-white"
              >
                <Icon icon={PlusIcon} size="sm" style={{ color: '#FFFFFF' }} />
                Add new
              </button>
            </div>
          ) : null}
        </div>
        {fakeTable.length > 0 ? (
          <LeaveTypesTable fakeTable={fakeTable} />
        ) : (
          <div className="flex h-[50vh] w-full flex-col items-center justify-center gap-4">
            <EmptyStateIcon />
            <p className='font-semibold'>There are no leave types created</p>
            <button
              onClick={() => setIsAddNewTypeOpen(true)}
              className="flex items-center gap-1 rounded-lg bg-[#DC2626] px-5 py-1 text-white"
            >
              <Icon icon={PlusIcon} size="sm" style={{ color: '#FFFFFF' }} />
              Add new
            </button>
          </div>
        )}
      </div>
      <LeaveTypeDialog
        isOpen={isAddNewTypeOpen}
        onClose={() => setIsAddNewTypeOpen(false)}
        buttonTitle="Add leave type"
      >
        Add new leave type
      </LeaveTypeDialog>
    </>
  );
};
