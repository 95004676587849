import { TextInput } from '@tremor/react';
import { RiSearchLine } from '@remixicon/react';
import { useState } from 'react';
import { ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Field, Listbox, Select } from '@headlessui/react';

const fakeUser = [
  { id: 1, name: 'Fake name 1', access: 'Editor' },
  { id: 2, name: 'Fake name 2', access: 'Only view' },
  { id: 3, name: 'Fake name 3', access: 'Only view' },
];

export default function UsersTab() {
  const [userAccess, setUserAccess] = useState(fakeUser);

  const handleAccessChange = (userId: number, newAccess: string) => {
    setUserAccess((prevUsers) => {
      const newUsers = prevUsers.map((user) => {
        if (user.id === userId) {
          return { ...user, access: newAccess };
        }
        return user;
      });
      return newUsers;
    });
  };

  const handleDeleteUser = (id: number) => {
    setUserAccess((prev) => prev.filter((prev) => prev.id !== id));
  };

  return (
    <div>
      <TextInput
        icon={RiSearchLine}
        placeholder="Search member by name or email address"
        className="w-auto my-3"
      />
      <div>
        <table className="min-w-full">
          <tbody className="divide-y divide-gray-200 bg-white">
            {userAccess.map((item) => (
              <tr key={item.id}>
                <td className="px-4 py-2">{item.name}</td>
                <td className="px-4 py-2">
                  <div className="flex justify-end ">
                    <Listbox 
                      value={item.access}
                      onChange={(value) => handleAccessChange(item.id, value)}>
                      <div className='relative'>
                        <Listbox.Button
                          value={item.access}
                          className="w-[130px] flex justify-between items-center gap-2 rounded-lg border px-2 py-1"
                        >
                          {item.access}
                          <ChevronUpDownIcon color='gray' className='w-5'/>
                        </Listbox.Button>
                        <Listbox.Options className="absolute w-full mt-1 bg-white shadow-lg rounded-lg z-10 px-2 py-1 gap-2">
                          <Listbox.Option 
                            className="cursor-pointer hover:bg-tremor-background-muted px-2 py-1 rounded-lg"
                            value="Editor">
                            Editor
                          </Listbox.Option>
                          <Listbox.Option 
                            className="cursor-pointer hover:bg-tremor-background-muted px-2 py-1 rounded-lg"
                            value="Only view">
                            Only view
                          </Listbox.Option>
                        </Listbox.Options>
                      </div>
                    </Listbox>
                  </div>
                </td>
                <td className="flex justify-center px-4 py-2">
                  <button
                    onClick={() => handleDeleteUser(item.id)}
                    className="rounded-lg border-[1px] p-2 hover:bg-gray-400"
                  >
                    <XMarkIcon className="w-4" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
