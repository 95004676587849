import FolderElement from './FolderElement';
type ISystem = {
  id: number;
  name: string;
  owner: string | number;
}

interface IFolder {
  system: ISystem;
  files: number;
  size: string;
}

export default function Folders({ folders, showFolder }: { folders: IFolder[], showFolder: any }) {
  return (
    <div className="py-4">
      <h3 className="font-semibold text-gray-900">Folders</h3>
      <div className='flex flex-row gap-3 mt-4 overflow-x-auto'>
        {folders?.map((folder) => (
          <FolderElement folder={folder} key={folder.system.id} onClick={showFolder}/>
        ))}
      </div>
    </div>
  );
}
