import axios from 'axios';

const API_HOST = process.env.REACT_APP_API_HOST;

async function uploadFile(file: FormData, folder: string | null) {
  const params = folder ? { folder } : {};
  const { data } = await axios.post(`${API_HOST}/v1/document/upload`, file, {
    params
  });
  return data;
}

async function getDocuments(
  page?: number,
  limit?: number,
  filters?: string,
) {
  const { data } = await axios.get(`${API_HOST}/v1/document`, {
    params: { limit, page, filters },
  });
  return data;
}

async function deleteDocumentById(id: string | number) {
  const { data } = await axios.delete(`${API_HOST}/v1/document/${id}`);
  return data;
}

async function updateDocument(id: string | number, newName: string) {
  const { data } = await axios.patch(`${API_HOST}/v1/document/${id}`, {
    name: newName,
  });
  return data;
}

async function deleteDocuments(ids: string[]) {
  const { data } = await axios.delete(`${API_HOST}/v1/document`, {
    data: { ids },
  });
  return data;
}

async function moveDocumentToFolder(id: string, folderId: string) {
  const { data } = await axios.patch(`${API_HOST}/v1/document/move/${id}`, {
    folder: folderId,
  });
  return data;
}

async function downloadDocument(id: string) {
  const response = await axios.get(`${API_HOST}/v1/document/${id}`);
  return response;
}

export default {
  getDocuments,
  uploadFile,
  deleteDocumentById,
  updateDocument,
  deleteDocuments,
  moveDocumentToFolder,
  downloadDocument,
};
