import axios from 'axios';
import { useEffect, useState } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import { useTeams } from './hooks';
import { EditIcon } from '../../assets/EditIcon';
import { useQuery } from 'react-query';
import { Spinner } from '../../ui/Spinner';
import { Pagination } from '../../ui/component/pagination';
import { DeletePeopleDialog } from './Dialogs/DeletePeopleDialog';
import { AddUserDialog } from './Dialogs/AddUserDialog';
import { EditUserDialog } from './Dialogs/EditUserDialog';
import { CreateTeamDialog } from './Dialogs/CreateTeamDialog';
import { TextInput } from '@tremor/react';
import { RiSearchLine } from '@remixicon/react';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { EditTeamDialog } from './Dialogs/EditTeamDialog';
import { DeleteTeamDialog } from './Dialogs/DeleteTeamDialog';

export function PeopleRoute() {
  const [isAddDialogOpen, setIsAddDialogOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const [isCreateTeamDialogOpen, setIsCreateTeamDialogOpen] = useState<boolean>(false);
  const [isEditTeamDialogOpen, setIsEditTeamDialogOpen] = useState<boolean>(false);
  const [ isDeleteTeamDialogOpen, setIsDeleteTeamDialogOpen] = useState<boolean>(false);

  const limit = 20;
  const [currentTeam, setCurrentTeam] = useState<number>(0);
  const { data: team_data, refetch: refetch_team } = useTeams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentUser, setCurrentUser] = useState<any>();
  const [currentTeamInfo, setCurrentTeamInfo] = useState({
    id: '',
    name: '',
    description: '',
    lead_id: '',
    lead_name: '',
    email: '',
    role: '',
    image: '',
    users: [],
  });

  const { data: response = [], refetch } = useQuery({
    queryKey: [
      'people',
      { team: currentTeam, page: currentPage, limit: limit },
    ],
    queryFn: async ({ queryKey }) => {
      setIsLoading(true);
      const { data = [] } = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_HOST}/v1/people`,
        params: {
          page: currentPage,
          limit: limit,
          team: currentTeam,
        },
      });
      setIsLoading(false);

      return data;
    },
    staleTime: 300000,
    cacheTime: 900000,
  });

  useEffect(() => {
    setCurrentPage(0);
  }, [currentTeam]);

  const handleTeamChange = (team: any) => {
    setCurrentTeam(team.id);
    setCurrentTeamInfo(team);
    refetch();
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    refetch();
  };

  const handleDeleteUser = async (user: any) => {
    setCurrentUser(user);
    setIsDeleteDialogOpen(true);
  };

  const handleEditUser = async (user: any) => {
    setCurrentUser(user);
    setIsEditDialogOpen(true);
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      <AddUserDialog
        isOpen={isAddDialogOpen}
        onClose={() => {
          setIsAddDialogOpen(false);
        }}
      />

      <DeletePeopleDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        refetch={refetch}
        user={currentUser}
      />

      <EditUserDialog
        isOpen={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        refetch={refetch}
        user={currentUser}
      />

      <CreateTeamDialog
        isOpen={isCreateTeamDialogOpen}
        onClose={() => setIsCreateTeamDialogOpen(false)}
      />

      <EditTeamDialog
        isOpen={isEditTeamDialogOpen}
        onClose={() => setIsEditTeamDialogOpen(false)}
        currentTeamInfo={currentTeamInfo}
        refetch_team={refetch_team}
        handleTeamChange={handleTeamChange}
      />

      <DeleteTeamDialog 
        isOpen={isDeleteTeamDialogOpen}
        onClose={() => setIsDeleteTeamDialogOpen(false)}
        team={currentTeamInfo}
        refetchTeam={refetch_team}
        handleTeamChange={handleTeamChange}
      />

      <div className="mb-2 flex flex-row justify-between">
        <div className="cursor-pointer border-gray-200 text-center text-sm font-medium text-gray-500 ">
          <ul className="flex w-full overflow-x-auto">
            <li
              key={0}
              className={`${
                currentTeam === 0
                  ? 'border-[#DC2626] text-gray-700'
                  : 'border-gray-200'
              } flex-shrink-0 border-b-2  transition-colors duration-300 ease-in-out hover:border-[#DC2626] hover:text-gray-600`}
              onClick={() =>
                handleTeamChange({
                  id: 0,
                  name: '',
                  description: '',
                  lead_id: '',
                  lead_name: '',
                  email: '',
                  role: '',
                  image: '',
                  users: [],
                })
              }
            >
              <div className="inline-block rounded-t-lg border-transparent p-4">
                All people
              </div>
            </li>
            {team_data &&
              !team_data?.status &&
              team_data?.map((team: { id: number; name: string }) => {
                return (
                  <li
                    key={team.id}
                    className={`${
                      currentTeam === team.id
                        ? 'border-[#DC2626] text-gray-700'
                        : 'border-gray-200'
                    } flex-shrink-0 border-b-2  transition-colors duration-300 ease-in-out hover:border-[#DC2626] hover:text-gray-600`}
                    onClick={() => handleTeamChange(team)}
                  >
                    <div className="inline-block rounded-t-lg  border-transparent p-4">
                      {team.name}
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="flex items-center">
          <button
            onClick={() => setIsCreateTeamDialogOpen(true)}
            className="rounded-lg bg-[#FEE9E9] px-4 py-[10px] text-sm font-semibold text-[#DC2626]"
          >
            Create Team
          </button>
        </div>
      </div>

      <div className="flex flex-col rounded-lg  bg-white shadow ring-1 ring-black ring-opacity-5">
        {currentTeam === 0 ? (
          <div className="border-b border-gray-200">
            <div className="flex flex-row items-center justify-between px-4 py-5 md:px-6">
              <div>
                <h1 className="text-xl font-semibold text-gray-900">People</h1>
                <p className="mt-2 text-sm text-gray-600">
                  A list of all the users in your company including their name,
                  title, email and role.
                </p>
              </div>

              <div className="flex flex-row items-center gap-4">
                <TextInput
                  // value={searchDocument}
                  // onChange={(event) => setSearchDocument(event.target.value)}
                  icon={RiSearchLine}
                  placeholder="Search by document here"
                  className="w-auto lg:w-[300px]"
                />
                <button
                  className="rounded-lg bg-red-500 px-4 py-[10px] text-sm font-semibold text-[#EAECF0]"
                  onClick={() => setIsAddDialogOpen(true)}
                >
                  <div className="flex flex-row items-center gap-2 text-white">
                    <PlusIcon className="h-5 w-5" />
                    Add User
                  </div>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="border-b border-gray-200">
            <div className="flex flex-row justify-between px-4 py-5 md:px-6">
              <section className="flex flex-col gap-3">
                <div className="flex flex-row gap-3">
                  <h1 className="font-semibold">{currentTeamInfo.name}</h1>
                  {currentTeamInfo?.role ? (
                    <span className="rounded-lg bg-[#FEE9E9] px-4 text-[#DC2626]">
                    {currentTeamInfo.role}
                  </span>
                  ) : null}
                </div>
                <div>
                  <p className="text-gray-600">{currentTeamInfo.description}</p>
                </div>
                <div className="flex flex-col gap-2">
                  <h3>Team Lead</h3>
                  <p className="flex items-center gap-3 text-gray-600">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={currentTeamInfo.image}
                      alt=""
                    />
                    {currentTeamInfo.lead_name}
                  </p>
                </div>
              </section>
              <section className="shrink-0 pl-3">
                <div className="flex flex-row gap-2">
                  <button 
                    onClick={() => setIsDeleteTeamDialogOpen(true)}
                    className="rounded-lg border-[1px] border-gray-200 p-2">
                    <TrashIcon color="red" className="w-5" />
                  </button>
                  <button
                    className="rounded-lg border-[1px] border-gray-200 p-2"
                    onClick={() => setIsEditTeamDialogOpen(true)}
                  >
                    <PencilSquareIcon color="black" className="w-6" />
                  </button>
                  <button
                    className="rounded-lg bg-red-500 px-4 py-[10px] text-sm font-semibold text-[#EAECF0]"
                    onClick={() => setIsAddDialogOpen(true)}
                  >
                    <div className="flex flex-row items-center gap-2 text-white">
                      <PlusIcon className="h-5 w-5" />
                      Add User
                    </div>
                  </button>
                </div>
              </section>
            </div>
          </div>
        )}

        {isLoading ? (
          <Spinner className="my-10" />
        ) : (
          <table className="min-w-full">
            <thead className="border-b border-gray-200">
              <tr>
                <th
                  scope="col"
                  className="py-3 pl-4 pr-3 text-left text-xs font-medium text-gray-600 sm:pl-6"
                >
                  Name
                </th>

                <th
                  scope="col"
                  className="px-3 py-3 text-left text-xs font-medium text-gray-600"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-left text-xs font-medium text-gray-600"
                >
                  Office
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-left text-xs font-medium text-gray-600"
                >
                  Teams
                </th>
                {/* <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium text-gray-600"
              ></th> */}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {response?.data &&
                response?.data?.map((user: any) => (
                  <tr key={user.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                      <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0">
                          <img
                            className="h-10 w-10 rounded-full"
                            src={user.safe_profile_picture_url}
                            alt=""
                          />
                        </div>
                        <div className="ml-4">
                          <div className="font-medium text-gray-900">
                            {user.full_name}
                          </div>
                          <div className="text-gray-500">{user.role_name}</div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className="w-fit rounded-2xl bg-[#FDF0DE] py-[2px] pl-1.5 pr-2 text-[#DB7D01]">
                        <div className="flex items-center gap-1">
                          <div className="h-2 w-2 rounded-full bg-[#DB7D01]"></div>
                          {user.status_now}
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {user.office_name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className="w-fit rounded-2xl bg-[#FEE9E9] px-3 py-[2px] text-[#DC2626]">
                        {user.team}
                      </div>
                    </td>
                    <td className="w-[120px] whitespace-nowrap px-4 py-4 text-sm text-gray-500">
                      <div className="flex items-center gap-4">
                        <button onClick={() => handleDeleteUser(user)}>
                          <TrashIcon color="red" className="w-5" />
                        </button>
                        <button onClick={() => handleEditUser(user)}>
                          <EditIcon />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {!isLoading && (
          <Pagination
            limit={limit}
            page={currentPage}
            count={response?.count}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
}
