import { useState } from "react"
import { Checkbox } from "../../../ui/component/checkbox"




const fakeRoles = [
  { id: 1, name: "Admin", view: true, edit: true},
  { id: 2, name: "Developer", view: true, edit: false},
  { id: 3, name: "Developer 1", view: true, edit: false},
  { id: 4, name: "Developer 2", view: true, edit: false}
]

export default function RolesTab(){

  const [ rolesAccess, setRolesAccess ] = useState(fakeRoles);

  const handleCheckboxChange = (id: number, ability: 'view' | 'edit') => {
    setRolesAccess((prevRoles) => {
      const newRoles = [...prevRoles];
      newRoles[id] = {
        ...newRoles[id],
        [ability]: !newRoles[id][ability],
      };
      return newRoles;
    });
  };

  return (
    <div>
      <table className="min-w-full">
        <tbody className="divide-y divide-gray-200 bg-white">
          {rolesAccess.map((item) => (
            <tr key={item.id}>
              <td className="py-3">
                {item.name}
              </td>
              <td >
                <div className="flex flex-row items-center gap-2">
                <Checkbox 
                  color="rose" 
                  checked={item.view} 
                  onChange={() => handleCheckboxChange(item.id - 1, "view")}
                  className="border-[1px] rounded-md"/>
                  View
                </div>
              </td>
              <td>
              <div className="flex flex-row items-center gap-2">
              <Checkbox 
                color="rose" 
                checked={item.edit} 
                onChange={() => handleCheckboxChange(item.id - 1, "edit")}
                className="border-[1px] rounded-md"/>
                Edit
              </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}