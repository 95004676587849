import { BigFolderIcon } from "../../../assets/DocumentManagementIcon/Folder";

type ISystem = {
  id: number;
  name: string;
  owner: string | number;
}

interface IFolder {
  system: ISystem;
  files: number;
  size: string;
}

export default function FolderElement ({folder, onClick}: {folder: IFolder, onClick: any}) {
  return (
    <div 
      className="max-w-[250px] min-w-[210px] mb-2
      border-[1px] border-gray-200 rounded-xl px-4 py-4
      cursor-pointer shadow hover:bg-[#FAFAFA]"
      onClick={() => onClick(folder.system.id)}>
        <BigFolderIcon />
        <div className="flex flex-col mt-3">
          <h4 className="font-semibold">{folder.system.name}</h4>
          <p className="flex items-center text-gray-400 text-sm mt-1">{folder.files} Files <span className="inline-block w-[6px] h-[6px] bg-gray-400 rounded-full mx-2"></span> {folder.size} </p>
        </div>
    </div>
  )
}