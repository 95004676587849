import {
  EllipsisHorizontalIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import {
} from '@heroicons/react/20/solid';
import { Menu, Popover, Position } from 'evergreen-ui';
import {
  DocumentDuplicateIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/solid';
import { useState } from 'react';
import { LeaveTypeDialog } from './LeaveTypeDialog';
import DeleteDialog from './DeleteDialog';



interface ILeaveType {
  id: string;
  title: string;
  leaveStatus: string;
  workExperience: string;
  quantityOfDays: string;
}

export const LeaveTypesTable = ({ fakeTable}: any) => {
  const [ isEditLeaveTypeOpen, setIsEditLeaveTypeOpen ] = useState<boolean>(false);
  const [ isDeleteDialogOpen, setIsDeleteDialogOpen ] = useState<boolean>(false);
  const [ currentLeaveType, setCurrentLeaveType ] = useState<ILeaveType>({
    id: '',
    title: '',
    leaveStatus: '',
    workExperience: '',
    quantityOfDays: ''
  })

  const handleEditLeaveType = (leaveType: ILeaveType) => {
    setCurrentLeaveType(leaveType);
    setIsEditLeaveTypeOpen(true);
  }

  const handleDeleteLeaveType = (leaveType: ILeaveType) => {
    setCurrentLeaveType(leaveType);
    setIsDeleteDialogOpen(true);
  } 

  return(
    <div className="overflow-hidden">
      <div className="mt-1 flow-root w-full">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle border-[1px] rounded-2xl px-5 py-3">
            <table className="w-full divide-y divide-gray-300 ">
              <thead >
                <tr>
                  <th
                    scope="col"
                    className="pl-7 pb-3 pt-4 text-left text-sm font-medium text-gray-500"
                  >
                    Leave Type
                  </th>
                  <th
                    scope="col"
                    className="text-left text-sm font-medium text-gray-500"
                  >
                    Leave Status
                  </th>
                  <th
                    scope="col"
                    className=" text-left text-sm font-medium text-gray-500"
                  >
                    Work experience
                  </th>
                  <th
                    scope="col"
                    className="text-left text-sm font-medium text-gray-500"
                  >
                    Quantity of days
                  </th>
                  <th scope="col" className="relative ">
                    <span className="sr-only">View</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 ">
                {fakeTable?.map((item: ILeaveType) => (
                  <tr key={item.id}>
                    <td className="pl-7 py-5 whitespace-nowrap text-sm font-medium text-gray-900 ">
                        {item?.title}
                    </td>
                    <td className="whitespace-nowrap text-sm text-gray-500 ">
                      <div className={`${item?.leaveStatus === "Paid" ? "bg-[#DCFCE7] text-[#15803D]" : "bg-[#FEE2E2] text-[#DC2626]"}  rounded-xl w-fit px-3 py-1 `}>
                        {item?.leaveStatus}
                      </div>
                    </td>
                    <td className="whitespace-nowrap text-sm text-gray-500">
                      {item?.workExperience}
                    </td>
                    <td className="whitespace-nowrap text-sm text-gray-500">
                      {item?.quantityOfDays}
                    </td>
                    <td >
                      <div className='flex justify-center'>
                      <Popover
                        position={Position.BOTTOM_RIGHT}
                        content={
                          <Menu>
                            <Menu.Group>
                              <Menu.Item>
                                <div
                                  onClick={() => handleEditLeaveType(item)}
                                  className="flex flex-row flex-nowrap gap-2 text-gray-600"
                                >
                                  <PencilSquareIcon
                                    className="w-4"
                                    color="gray"
                                  />
                                  Edit
                                </div>
                              </Menu.Item>
                              <Menu.Item>
                                <div
                                  onClick={() => handleEditLeaveType(item)}
                                  className="flex flex-row flex-nowrap gap-2 text-gray-600"
                                >
                                  <DocumentDuplicateIcon
                                    className="w-4"
                                    color="gray"
                                  />
                                  Duplicate and edit
                                </div>
                              </Menu.Item>
                              <Menu.Item>
                                <div
                                  onClick={() => handleDeleteLeaveType(item)}
                                  className="flex flex-row flex-nowrap gap-2 text-[#DC2626]"
                                >
                                  <TrashIcon className="w-4" color="red" />
                                  Delete
                                </div>
                              </Menu.Item>
                            </Menu.Group>
                          </Menu>
                        }
                      >
                        <EllipsisHorizontalIcon className="h-5 w-5 cursor-pointer" />
                      </Popover>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <LeaveTypeDialog
      isOpen={isEditLeaveTypeOpen}
      onClose={() => setIsEditLeaveTypeOpen(false)}
      currentTypesInfo={currentLeaveType}
      buttonTitle="Save">
      Edit leave type
      </LeaveTypeDialog>
      <DeleteDialog 
        open={isDeleteDialogOpen}
        setOpen={setIsDeleteDialogOpen}
        item={currentLeaveType}
      />
    </div>
  )
}