import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@tremor/react";
import { LeaveTypesComponent } from "./components/LeaveTypesComponent";

export function SettingsRoute() {
  return (
    <>
      <div className="border-b-2 border-[#E4E4E7]">
        <h1 className="text-2xl font-bold pb-5 text-tremor-content-strong">
          Settings
        </h1>
      </div>
      <div className="my-5">
        <TabGroup>
          <TabList variant="solid" color="rose" >
            <Tab className="py-2 px-3 text-md">
              Open Positions
            </Tab>
            <Tab className="py-2 px-3 text-md">
              Leave Types
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <div> Open Positions</div>
            </TabPanel>
            <TabPanel>
              <LeaveTypesComponent  />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </>
  );
}
