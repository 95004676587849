import { Dialog } from 'evergreen-ui';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { TeamIcon } from '../../../assets/TeamIcon';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { updateTeam } from '../../../network';
import { FormGroup } from '../../../ui/form/FormGroup';
import { Input } from '../../../ui/form/Input';
import { Select } from '../../../ui/form/Select';
import { usePeople, useRoles } from '../hooks';
import { MultiSelectWithBadges } from '../../../app/components/MultiSelectWithBadges';
import { Textarea } from '../../../ui/form/Textarea';
import { useToast } from '../../../utils/useToast';
import * as yup from 'yup';
import { AxiosResponse } from 'axios';

type Props = {
  isOpen: boolean;
  onClose(): void;
  refetch_team(): void,
  currentTeamInfo: any;
  handleTeamChange(team: any): void;
};

export const EditTeamDialog = ({ isOpen, onClose, currentTeamInfo,refetch_team, handleTeamChange}: Props) => {
  const { toast } = useToast();

  const { mutateAsync} = useMutation<AxiosResponse<any>, Error, { id: string, data: any }>(
    async ({id, data}) => updateTeam(id, data),{
      onSuccess: () => {
        refetch_team();
        toast({
          variant: 'success',
          title: 'Team successfully updated.',
        });
        handleTeamChange(currentTeamInfo);
      },
      onError: () => {
        toast({
          variant: 'warning',
          title: 'Failed to update the team.',
        });
    }
  });
  const { data: role_data } = useRoles();
  const { data: people_data } = usePeople();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: currentTeamInfo?.name,
      department: currentTeamInfo?.department,
      teamLead: currentTeamInfo?.lead_id,
      users: currentTeamInfo?.users.map((user: any) => user),
      description: currentTeamInfo?.description,
    },
    validationSchema: yup.object({
      name: yup.string().required(),
      department: yup.string().required(),
      teamLead: yup.string().required(),
      users: yup.array().required(),
    }),
    onSubmit: async ( values ) => {
      mutateAsync({ id: currentTeamInfo.id, data: {...values }});
      
      closeDialog();
    },
  });

  function closeDialog() {
    refetch_team();
    formik.resetForm();
    onClose();
  }

  function findError(fieldName: keyof typeof formik.initialValues) {
    return formik.touched[fieldName] && formik.errors[fieldName]
      ? String(formik.errors[fieldName])
      : undefined;
  }

  return (
    <>
      <Dialog
        isShown={isOpen}
        onCloseComplete={onClose}
        hasFooter={false}
        hasHeader={false}
      >
        <div className="flex flex-col">
          <div className="mb-4 flex flex-row justify-between pt-6">
            <div className="rounded-[10px] bg-[#FEE9E9] p-3 shadow-sm">
              <TeamIcon />
            </div>
            <XMarkIcon
              className="h-6 w-6 cursor-pointer"
              onClick={closeDialog}
            />
          </div>

          <div className="mb-5 flex flex-col gap-1">
            <div className="text-lg font-semibold">Team Detail</div>
            <div className="text-sm font-normal text-[#475467]">
              Create your new team and invite colleagues to collaborate on this
              project.
            </div>
          </div>

          <form onSubmit={formik.handleSubmit} className="space-y-3">
            <FormGroup label="Team Name" htmlFor="name-input">
              <Input
                id="name-input"
                type="text"
                name="name"
                error={findError('name')}
                value={formik.values.name}
                onChange={formik.handleChange}
                placeholder="Enter name"
              />
            </FormGroup>
            <FormGroup label="Department" htmlFor="department-input">
              <Select
                id="department-input"
                name="department"
                value={formik.values.department}
                onChange={formik.handleChange}
                error={findError('department') }
              >
                {role_data?.map((param: any, index: number) => (
                  <option key={index} value={param.value}>
                    {param.title}
                  </option>
                ))}
              </Select>
            </FormGroup>
            <FormGroup label="Team Lead" htmlFor="team-lead-input">
              <Select
                id="team-lead-input"
                name="teamLead"
                value={formik.values.teamLead}
                onChange={formik.handleChange}
                error={findError('teamLead')}
              >
                <option value="" disabled>Select Team Lead</option>
                {people_data?.data?.map((person: any) => (
                  <option key={person.id} value={person.id}>
                    {person.full_name}
                  </option>
                ))}
              </Select>
            </FormGroup>
            <FormGroup label="Select User">
              <MultiSelectWithBadges
                users={people_data?.data}
                value={formik.values.users}
                onChange={(selectedUsers) =>
                  formik.setFieldValue('users', selectedUsers)
                }
              />
              {formik.touched.users && formik.errors.users ? (
                <div className="mt-1 rounded-sm bg-rose-600 px-1.5 py-0.5 text-xs text-white">
                  {findError('users')}
                </div>
              ) : null}
            </FormGroup>
            <FormGroup label="Description" htmlFor="description-input">
              <Textarea
                id="description-input"
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
                error={findError('description')}
              />
            </FormGroup>
          </form>

          <div className="mb-6 mt-8 flex flex-row justify-between gap-3">
            <button
              className="w-full rounded-lg border border-[#D0D5DD] bg-white px-[18px] py-[10px] text-base font-semibold text-[#344054] shadow-sm"
              onClick={closeDialog}
            >
              Cancel
            </button>
            <button
              className="w-full rounded-lg bg-[#DC2626] px-[18px] py-[10px] text-base font-semibold text-white shadow-sm disabled:bg-[#47546789]"
              onClick={() => formik.handleSubmit()}
            >
              Save
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
