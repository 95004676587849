
export default function UploadFileSection({handleFileChange}: any) {
  return (
    <div className="flex w-full items-center justify-center px-6">
      <label
        className="flex w-full cursor-pointer flex-col items-center justify-center rounded-lg 
        border-2 border-dashed border-gray-300  hover:bg-tremor-background-subtle h-40"
      >
        <div className="flex flex-col items-center justify-center py-4 ">
          <svg
            width="42"
            height="34"
            viewBox="0 0 42 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 5C0 2.51472 2.01472 0.5 4.5 0.5H37.5C39.9853 0.5 42 2.51472 42 5V29C42 31.4853 39.9853 33.5 37.5 33.5H4.5C2.01472 33.5 0 31.4853 0 29V5ZM3 25.1213V29C3 29.8284 3.67157 30.5 4.5 30.5H37.5C38.3284 30.5 39 29.8284 39 29V25.1213L33.6213 19.7426C32.4497 18.5711 30.5503 18.5711 29.3787 19.7426L27.6213 21.5L29.5607 23.4393C30.1464 24.0251 30.1464 24.9749 29.5607 25.5607C28.9749 26.1464 28.0251 26.1464 27.4393 25.5607L17.1213 15.2426C15.9497 14.0711 14.0503 14.0711 12.8787 15.2426L3 25.1213ZM23.25 9.5C23.25 8.25736 24.2574 7.25 25.5 7.25C26.7426 7.25 27.75 8.25736 27.75 9.5C27.75 10.7426 26.7426 11.75 25.5 11.75C24.2574 11.75 23.25 10.7426 23.25 9.5Z"
              fill="#A1A1AA"
            />
          </svg>
          <p className="mt-3">
            <span className="font-semibold">
              Upload a file
            </span> <span className="text-gray-400 ">
              or drag and drop
              </span> 
            </p>
          <p className="text-gray-300 ">PDF or DOCX</p>
        </div>
        <input  
          multiple 
          type="file" 
          className="hidden"  
          accept=".pdf, .docx"
          onChange={handleFileChange}/>
      </label>
    </div>
  );
}
