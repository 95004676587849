import { Icon, TextInput } from '@tremor/react';
import { RiSearchLine } from '@remixicon/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import FilesTable from './FilesTable';
import { ChevronLeftIcon, FunnelIcon } from '@heroicons/react/24/solid';
import { Menu, Popover, Position } from 'evergreen-ui';
import { Checkbox } from '../../../ui/component/checkbox';
import { useEffect, useState } from 'react';
import UploadNewFileModal from './UploadNewFileModal';
import RenameModal from './RenameModal';
import DeleteFileModal from './DeleteFileModal';
import SettingsModal from './SettingsModal';
import filesManagement from '../../../network/filesManagement';
import usePagination from '../../../hooks/usePagination';

const fileType  = [
  { id: 1, name: 'pdf' },
  { id: 2, name: 'docx' },
]

const fakeOwners = [
  { id: "1", name: 'Owner 1' },
  { id: "2", name: 'Owner 2' },
  { id: "3", name: 'Owner 3' },
];

const uploadedTimeVariants = [
  { id: 1, label: '1 week' },
  { id: 2, label: '1 month' },
  { id: 3, label: '6 months' },
  { id: 4, label: '1 year' },
];

interface IFiles {
  id: string;
  date_updated: string;
  date_uploaded: string;
  folder: string | null;
  size: string;
  owner: {
    email: string;
    id: string;
    name: string;
  };
}

export default function Files({
  folders,
  refetchFolders,
}: {
  folders: any;
  refetchFolders: any;
}) {
  const [isOpenUploadFileModal, setIsOpenNewFileModal] = useState(false);
  const [isOpenRenameModal, setIsOpenRenameModal] = useState(false);
  const [isOpenDeleteFileModal, setIsOpenDeleteFileModal] = useState(false);
  const [isOpenSettingsModal, setIsOpenSettingsModal] = useState(false);
  const [currentFile, setCurrentFile] = useState({});
  const [searchDocument, setSearchDocument] = useState('');
  const [limit, setLimit] = useState(10);

  const [selectedFileTypes, setSelectedFileTypes] = useState<string[]>([]);
  const [selectedOwners, setSelectedOwners] = useState<string[]>([]);
  const [selectedUploadedTime, setSelectedUploadedTime] = useState<string[]>([]);

  const filters = {
    searchDocument: searchDocument,
    filesType: selectedFileTypes,
    owners: selectedOwners,
    uploadedTime: selectedUploadedTime,
  }

  const {
    data: documents = [],
    page,
    totalItems,
    setDirectPage,
    refetch,
  } = usePagination({
    key: 'documents',
    params: filters,
    fetcher: filesManagement.getDocuments,
    initialPage: 0,
    initialItemsPerPage: limit,
  });

  useEffect(() => {
    refetch();
  }, [searchDocument]);

  const handleCheckboxClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleFileTypeApply = (event: React.MouseEvent) => {
    event.stopPropagation();
    refetch();
  };

  const handleFileTypeClearAll = (event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedFileTypes([]);
    refetch();
  };

  const handleOwnerApply = (event: React.MouseEvent) => {
    event.stopPropagation();
    refetch();
  };

  const handleOwnerClearAll = (event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedOwners([]);
    refetch();
  };

  const handleUploadedApply = (event: React.MouseEvent) => {
    event.stopPropagation();
    refetch();
  };

  const handleUploadedClearAll = (event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedUploadedTime([])
    refetch();
  };

  const handleUploadNewFileModal = () => {
    setIsOpenNewFileModal((prev) => !prev);
  };

  const handleOpenRenameModal = (file: any) => {
    setCurrentFile(file);
    setIsOpenRenameModal((prev) => !prev);
  };

  const handleDeleteFileModal = (file: any) => {
    setCurrentFile(file);
    setIsOpenDeleteFileModal((prev) => !prev);
  };

  const handleSettingsModal = (file: any) => {
    setCurrentFile(file);
    setIsOpenSettingsModal((prev) => !prev);
  };

  const deleteFile = async (id: string | number) => {
    await filesManagement
      .deleteDocumentById(id)
      .then(() => {
        refetch();
        refetchFolders();
        setIsOpenDeleteFileModal(false);
      })
      .catch((error) => console.log(error));
  };

  const renameFile = async (id: string | number, newName: string) => {
    newName.length > 0 &&
      (await filesManagement.updateDocument(id, newName).then(() => {
        refetch();
        setIsOpenRenameModal(false);
      }));
  };

  const handleDeleteDocuments = async (ids: string[]) => {
    await filesManagement
      .deleteDocuments(ids)
      .then(() => {
        refetch();
        refetchFolders();
      })
      .catch((error) => console.log(error));
  };

  const handleChangeDocumentFolder = (
    event: React.MouseEvent,
    id: string,
    folderId: string,
  ) => {
    event.stopPropagation();
    filesManagement
      .moveDocumentToFolder(id, folderId)
      .then(() => {
        refetch();
        refetchFolders();
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="mt-5 rounded-xl border-[1px] border-gray-100 px-4 pb-1 pt-4 shadow">
      <div className="mb-2 flex items-center justify-between">
        <h3 className="font-semibold">All Files</h3>
        <div className="flex flex-row gap-4">
          <TextInput
            value={searchDocument}
            onChange={(event) => setSearchDocument(event.target.value)}
            icon={RiSearchLine}
            placeholder="Search by document here"
            className="w-auto lg:w-[300px]"
          />
          <Popover
            minWidth={'100px'}
            content={
              <Menu>
                <Menu.Group>
                <Popover
                    position={Position.LEFT}
                    content={
                      <Menu>
                        <Menu.Group>
                          <div className="flex flex-col gap-1">
                            {fileType.map((item) => (
                              <Menu.Item
                                key={item.id}
                                onClick={handleCheckboxClick}
                              >
                                <div className="flex flex-row items-center gap-2">
                                  <Checkbox
                                    className="rounded-md border-[1px]"
                                    color="rose"
                                    checked={selectedFileTypes.includes(item.name)}
                                    onChange={() => {
                                      setSelectedFileTypes((prev) =>
                                        prev.includes(item.name)
                                          ? prev.filter((name) => name !== item.name)
                                          : [...prev, item.name]
                                      );
                                    }}
                                  />
                                  {item.name}
                                </div>
                              </Menu.Item>
                            ))}
                          </div>
                          <div className="mt-1 flex flex-row justify-center gap-2">
                            <button
                              className=" w-[40%] rounded-lg border-[1px] py-1 shadow"
                              onClick={handleFileTypeClearAll}
                            >
                              Clear all
                            </button>
                            <button
                              onClick={handleFileTypeApply}
                              className="w-[40%] rounded-lg border-[1px] shadow"
                            >
                              Apply
                            </button>
                          </div>
                        </Menu.Group>
                      </Menu>
                    }
                  >
                    <Menu.Item>
                      <div className="flex flex-row flex-nowrap gap-2">
                        <ChevronLeftIcon className="w-4" />
                        File type
                      </div>
                    </Menu.Item>
                  </Popover>

                  <Popover
                    position={Position.LEFT}
                    content={
                      <Menu>
                        <Menu.Group>
                          <div className="flex flex-col gap-1">
                            {fakeOwners.map((owner) => (
                              <Menu.Item
                                key={owner.id}
                                onClick={handleCheckboxClick}
                              >
                                <div className="flex flex-row items-center gap-2">
                                  <Checkbox
                                    className="rounded-md border-[1px]"
                                    color="rose"
                                    checked={selectedOwners.includes(owner.id)}
                                    onChange={() => {
                                      setSelectedOwners((prev) =>
                                        prev.includes(owner.id)
                                          ? prev.filter((id) => id !== owner.id)
                                          : [...prev, owner.id]
                                      );
                                    }}
                                  />
                                  {owner.name}
                                </div>
                              </Menu.Item>
                            ))}
                          </div>
                          <div className="mt-1 flex flex-row justify-center gap-2">
                            <button
                              className=" w-[40%] rounded-lg border-[1px] py-1 shadow"
                              onClick={handleOwnerClearAll}
                            >
                              Clear all
                            </button>
                            <button
                              onClick={handleOwnerApply}
                              className="w-[40%] rounded-lg border-[1px] shadow"
                            >
                              Apply
                            </button>
                          </div>
                        </Menu.Group>
                      </Menu>
                    }
                  >
                    <Menu.Item>
                      <div className="flex flex-row flex-nowrap gap-2">
                        <ChevronLeftIcon className="w-4" />
                        Owner
                      </div>
                    </Menu.Item>
                  </Popover>

                  <Popover
                    position={Position.LEFT}
                    content={
                      <Menu>
                        <Menu.Group>
                          <div className="flex flex-col gap-1">
                            {uploadedTimeVariants.map((item) => (
                              <Menu.Item
                                key={item.id}
                                onClick={handleCheckboxClick}
                              >
                                <div className="flex flex-row items-center gap-2">
                                  <Checkbox
                                    className="rounded-md border-[1px]"
                                    color="rose"
                                    checked={selectedUploadedTime.includes(item.label)}
                                    onChange={() => {
                                      setSelectedUploadedTime((prev) =>
                                        prev.includes(item.label)
                                          ? prev.filter((label) => label !== item.label)
                                          : [...prev, item.label]
                                      );
                                    }}

                                  />
                                  {item.label}
                                </div>
                              </Menu.Item>
                            ))}
                          </div>
                          <div className="mt-1 flex flex-row justify-center gap-2">
                            <button
                              className=" w-[40%] rounded-lg border-[1px] py-1 shadow"
                              onClick={handleUploadedClearAll}
                            >
                              Clear all
                            </button>
                            <button
                              onClick={handleUploadedApply}
                              className="w-[40%] rounded-lg border-[1px] shadow"
                            >
                              Apply
                            </button>
                          </div>
                        </Menu.Group>
                      </Menu>
                    }
                  >
                    <Menu.Item>
                      <div className="flex flex-row flex-nowrap gap-2">
                        <ChevronLeftIcon className="w-4" />
                        Uploaded
                      </div>
                    </Menu.Item>
                  </Popover>

                  <Menu.Item>
                    <div className="flex flex-row flex-nowrap gap-2">
                      <ChevronLeftIcon className="w-4" />
                      Last update
                    </div>
                  </Menu.Item>
                </Menu.Group>
              </Menu>
            }
          >
            <button
              className="flex items-center 
            rounded-lg border-[1px] px-3 py-1 font-normal shadow"
            >
              <Icon icon={FunnelIcon} size="sm" color="blue" />
              Filter
            </button>
          </Popover>
          <button
            onClick={() => handleUploadNewFileModal()}
            className="flex items-center gap-1 rounded-lg bg-[#DC2626] px-5 py-1 text-white"
          >
            <Icon icon={PlusIcon} size="sm" style={{ color: '#FFFFFF' }} />
            Upload new file
          </button>
        </div>
      </div>
      {documents.length > 0 ? (
        <FilesTable
          files={documents}
          handleOpenRenameModal={handleOpenRenameModal}
          handleDeleteFileModal={handleDeleteFileModal}
          handleSettingsModal={handleSettingsModal}
          handleDeleteDocuments={handleDeleteDocuments}
          folders={folders}
          handleChangeDocumentFolder={handleChangeDocumentFolder}
          pagination={{ limit, totalItems, page, setDirectPage }}
        />
      ) : (
        <p className='flex justify-center py-2'>
          No Files
        </p>
      )}
      <UploadNewFileModal
        open={isOpenUploadFileModal}
        setOpen={setIsOpenNewFileModal}
        refetch={refetch}
        folders={folders}
        refetchFolders={refetchFolders}
      />
      <RenameModal
        open={isOpenRenameModal}
        setOpen={setIsOpenRenameModal}
        file={currentFile}
        renameFile={renameFile}
        rename={'document'}
      >
        By renaming a file, it will also change in all folders.
      </RenameModal>
      <DeleteFileModal
        open={isOpenDeleteFileModal}
        setOpen={setIsOpenDeleteFileModal}
        file={currentFile}
        deleteFile={deleteFile}
      />
      <SettingsModal
        file={currentFile}
        open={isOpenSettingsModal}
        setOpen={setIsOpenSettingsModal}
      />
    </div>
  );
}
